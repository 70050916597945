.door
{
  transition: all 1.5s ease-in-out;
}

.doorOpen
{
  /*prespectiv creates the door open effect*/
  transform: translateX(-200px);
}

.doorOpen.remove
{
  transform: translateX(-200px) translateY(-500px);
  opacity: 0;
}

.remove
{
  transform: translateY(-500px);
  opacity: 0;
}